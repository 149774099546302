
import { defineComponent, onMounted, ref, computed } from "vue";
import PolicyService from "@/core/services/car/PolicyService";
import { useStore } from "vuex";
import { download, addLog } from "@/store/stateless/store";
import DocumentAction from "@/components/partials/logs/DocumentAction.vue";
import DocumentLogsModal from "@/components/partials/logs/DocumentLogsModal.vue";
import ReusableModal from "@/components/modals/forms/ReusableModal.vue";
import { Mutations } from "@/store/enums/StoreEnums";

interface DeleteDocParam {
  document_id: number;
}
interface PolicyBodyParam {
  policy_id: any;
}
interface DownloadDocBodyParam{
    policy: Array<any>;
    temp:  Array<any>;
}
export default defineComponent({
  name: "policy-documentssss-partials",
  components: {
    // QuotesAction,
    DocumentAction,
    DocumentLogsModal,
    ReusableModal,
  },
  props: {
    policyDocs: Array,
    modalId: String,
    showHeader: Boolean,
    showQuoteRefNo: Boolean,
    showDelete: Boolean,
    showEmptyDom: Boolean,
    showAllDownload: {
      type: Boolean,
      required: false,
      default: true
    },
    showLog: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDownload: {
      type: Boolean,
      required: false,
      default: true,
    },
    moduleType: {
      type: String,
      required: false,
      default: "policy",
    },
    isDisableButtons: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {};
  },
  setup() {
    const store = useStore();
    let deleteDocParam = {} as DeleteDocParam;
    let policyBodyParam = {} as PolicyBodyParam;
    let downloadDocBodyParam = {} as DownloadDocBodyParam;
    onMounted(() => {
      //
    });
    let getPolicyDomStatus = computed(() => {
      return store.getters.getPolicyDocumentModalDomStatus;
    });

    const assignDeleteDocument = (docId, policyId) => {
      deleteDocParam.document_id = docId;
      policyBodyParam.policy_id = policyId;
      deleteDocument();
    };
    const deleteDocument = () => {
      // isLoading.value = true;
      Promise.all([PolicyService.deleteDocument(deleteDocParam)]).then(
        (data) => {
          close();
          PolicyService.getPolicyDetails(policyBodyParam);
          PolicyService.getAllDocuments(policyBodyParam);
          PolicyService.getPolicyDocuments(policyBodyParam);
        }
      );
    };
    const confirmDelete = (item) => {
      isLoading.value = true;
      confirmEvent(item.document_id, item.policy_id);
    };
    const confirmEvent = (docId, policyId) => {
      console.log("confirm!");
      assignDeleteDocument(docId, policyId);
    };
    const cancelEvent = () => {
      console.log("cancel!");
    };

    const selected = ref();

    const openMouseEnter = ref(false);

    const ApiPath = process.env.VUE_APP_API_URL;

    const open = (e) => {
      selected.value = e;
      openMouseEnter.value = !openMouseEnter.value;
    };

    const documentLog = computed(() => {
      return store.getters.getDocumentLogs;
    });

    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    const dataItem = ref<any>(null);
    const isOpen = ref(false);
    const isLoading = ref(false);
    const OpenDelete = (id, policyId) => {
      dataItem.value = {
        document_id: id,
        policy_id: policyId,
      };
      isOpen.value = true;
    };

    const close = () => {
      isOpen.value = false;
      isLoading.value = false;
      dataItem.value = null;
      store.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, true);
    };

    let loading = computed(() => {
      return store.getters.getPolicyDocumentLoading;
    });
    const downloadZip = (policyDocs) => {
      
      let policyDocArray = Array<any>();
      let tempPolicyDocArray = Array<any>();
      policyDocs.forEach((policyDoc) =>{
         if (policyDoc.is_temp == 0  && policyDoc?.document_type?.document_type == 'verification'){
          policyDocArray.push(policyDoc.id);
         }
          if (policyDoc.is_temp == 1 && policyDoc?.document_type?.document_type == 'verification'){
          tempPolicyDocArray.push(policyDoc.id);
         }
      });
      downloadDocBodyParam.policy = policyDocArray;
       downloadDocBodyParam.temp = tempPolicyDocArray;
    //  console.log(downloadDocBodyParam);
     PolicyService.downloadZip(downloadDocBodyParam);
    };

    const getTextWidth = (text, font) => {
      const canvas = document.createElement("canvas");
      const context: any = canvas.getContext("2d");

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    };

    return {
      loading,
      confirmDelete,
      close,
      isLoading,
      OpenDelete,
      isOpen,
      dataItem,
      userPermissions,
      ApiPath,
      open,
      selected,
      download,
      getPolicyDomStatus,
      openMouseEnter,
      assignDeleteDocument,
      deleteDocument,
      confirmEvent,
      cancelEvent,
      addLog,
      documentLog,
      downloadZip,
      getTextWidth,
    };
  },
});
